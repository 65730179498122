import React from "react";

export function OpeningHours() {
  return (
    <ul className="opening_hours">
      <li>
        <span>Monday</span>
        <hr />
        <span className="opening_hours-closed">Closed</span>
      </li>
      <li>
        <span>Tuesday (Term Time Only)</span>
        <hr />
        <span>10:30PM - 03:00AM</span>
      </li>
      <li>
        <span>Wednesday</span>
        <hr />
        <span className="opening_hours-closed">Closed</span>
      </li>
      <li>
        <span>Thursday</span>
        <hr />
        <span>10:30 PM - 03:30 AM</span>
      </li>
      <li>
        <span>Friday</span>
        <hr />
        <span>10:30 PM - 03:30 AM</span>
      </li>
      <li>
        <span>Saturday</span>
        <hr />
        <span>10:30 PM - 03:30 AM</span>
      </li>
      <li>
        <span>Sunday</span>
        <hr />
        <span className="opening_hours-closed">Closed</span>
      </li>
    </ul>
  );
}
