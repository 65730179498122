import { Hero } from "components/Hero";
import { OpeningHours } from "components/OpeningHours";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

export default function AboutPage({ location }) {
  const title = "About Us | Plush Oxford";
  const description =
    "In the heart of central Oxford, Plush is an LGBTQ+ bar and club in an exciting venue, famed for incredible nights out, an awesome atmosphere & incredible drinks offers.";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero background={<StaticImage src="../images/heroAbout.jpeg" alt="" />}>
        <h1>About Us</h1>
      </Hero>
      <div className="about__container">
        <section>
          <h2>About Us</h2>
          <p>
            In the heart of central Oxford, Plush is an LGBTQ+ bar and club in
            an exciting venue, famed for incredible nights out, an awesome
            atmosphere &amp; incredible drinks offers.
          </p>
          <p>
            Plush is a privately-owned and operated bar which was established in
            Oxford in 2010, and which has been solely run and directed by
            members of the LGBTQ+ community since its inception.
          </p>
          <p>
            Plush has established itself as a leader in the provision of high
            quality atmosphere and entertainment at affordable prices in city
            centre Oxford.
          </p>
        </section>
        <section>
          <h2>Plush Bar &amp; Club</h2>
          <p>
            We are Oxfordshire's premier LGBTQ+ Nightclub, boasting two bars, a
            cocktail bar, luxurious seating &amp; VIP areas, state of the art
            sound and lighting, and some of the UK's best commercial DJ's every
            night.
          </p>
        </section>
        <section>
          <h2>Find Us</h2>
          <p>
            Plush is in central Oxford near Carfax Tower and the Westgate
            Shopping Centre. The venue is on Frewin Court, off the
            pedestrian-only Cornmarket Street, half-way between its intersection
            with George Street and High Street.
          </p>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.9100381717867!2d-1.260933484220999!3d51.75296857967631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876c6a2fce12f27%3A0xb915c0eb87e0c7cb!2sPlush%20Oxford!5e0!3m2!1sen!2suk!4v1661178718316!5m2!1sen!2suk"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            tabIndex={-1}
          />
        </section>
        <section>
          <h2>Opening Hours</h2>
          <OpeningHours />
        </section>
        <section>
          <h2>Plush Culture and Values</h2>
          <p>
            Plush has a zero tolerance policy and will not tolerate, amongst
            others, homophobia, transphobia, queerphobia, biphobia, racism,
            xenophobia, or abelism.
          </p>
          <p>
            Plush is committed to providing a safe atmosphere predominantly for
            the LGBTQ+ community, whilst welcoming all patrons who share our
            values and respect our culture. We want to ensure a good night out
            for everyone, and if this is not or was not the case for you, we
            strongly encourage you to speak to a member of staff or to contact
            us:{" "}
            <a className="animated_link" href="mailto:feedback@plushoxford.com">
              feedback@plushoxford.com
            </a>
            .
          </p>
        </section>
      </div>
    </>
  );
}
